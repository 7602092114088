/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Box,
} from '@mui/material';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { checkValidity } from '../checkValidity';
import { BASE_URL } from '../../../utils/constants';

export default function FetchCustomers() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));

  const [report, setReport] = useState('');
  const [currentId, setCurrentId] = useState('');
  const [send, setSend] = useState(false);
  const [currentName, setCurrentName] = useState('');

  const [openDialog, setOpenDialog] = React.useState(false);

  // const [dolapoAssigned, setDolapoAssigned] = useState('')
  // const [dolapoAttended, setDolapoAttended] = useState('')

  const [matthewAssigned, setMatthewAssigned] = useState('')
  const [matthewAttended, setMatthewAttended] = useState('')

  const [lovethAssigned, setLovethAssigned] = useState('')
  const [lovethAttended, setLovethAttended] = useState('')

  const [preciousAssigned, setPreciousAssigned] = useState('')
  const [preciousAttended, setPreciousAttended] = useState('')

  const salesExecutive = ['Abimbade Matthew', 'Benson Loveth', 'Precious Ukaegbu'];

  const handleClickOpen = (id, name) => {
    setCurrentId(id);
    setCurrentName(name);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setReport('');
    setCurrentId('');
    setCurrentName('');
    setOpenDialog(false);
  };
  useEffect(() => {
    if (!currentUser) {
      navigate('/', { replace: true });
    } else {
      setLoading(true);
      try {
        fetch(BASE_URL+'/accounts/customer', {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setMessage(data.error);
              setOpen(true);
              setLoading(false);
            } else {
              setCustomers(data.customer);

              for (let i = 0; i < salesExecutive.length; i++) {
                fetch(BASE_URL+'/get-assigned', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                  },
                  body: JSON.stringify({
                    executiveName: salesExecutive[i],
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (i === 0) {
                      setMatthewAssigned(data.value)
                      setMatthewAttended(data.attended)
                    } else if (i === 1) {
                      setLovethAssigned(data.value)
                      setLovethAttended(data.attended)
                    }else{
                      setPreciousAssigned(data.value)
                      setPreciousAttended(data.attended)
                    }
                  });
              }

              setLoading(false);
            }
          });
      } catch (e) {
        setOpen(true);
        setMessage(e.message);
        setLoading(false);
      }
    }
  }, []);
  return (
    <>
      {open ? <Alert severity="error">{message}</Alert> : null}

      {/* Attended information */}

      {loading ? null : (
        <Box>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TableRow>
                      <TableCell>Matthew</TableCell>
                      <TableCell>Assigned: {matthewAssigned}</TableCell>
                      <TableCell>Attended: {matthewAttended}</TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell>
                    <TableRow>
                      <TableCell>Loveth</TableCell>
                      <TableCell>Assigned: {lovethAssigned}</TableCell>
                      <TableCell>Attended: {lovethAttended}</TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell>
                    <TableRow>
                      <TableCell>Precious</TableCell>
                      <TableCell>Assigned: {preciousAssigned}</TableCell>
                      <TableCell>Attended: {preciousAttended}</TableCell>
                    </TableRow>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Remark dialog box */}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add or Edit Customer's Contact Remark"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Use this form to keep track how far you have gone with <b>{currentName}</b>. Ensure this is short and brief
            by being specific, and being able to use to write up to track <b>{currentName}</b> progress
          </DialogContentText>
          <TextField
            id="filled-textarea"
            label="Customer Report"
            placeholder="Write a customer report here"
            fullWidth
            value={report}
            onChange={(e) => setReport(e.target.value)}
            multiline
            variant="filled"
            style={{ marginTop: '15px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="success"
            disabled={send}
            onClick={() => {
              if (!report) {
                window.alert('Report can not be empty');
              } else {
                checkValidity()
                  .then(() => {
                    setSend(true);

                    fetch(BASE_URL+'/update-report', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                      },
                      body: JSON.stringify({
                        id: currentId,
                        report,
                      }),
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        if (data.invalid) {
                          window.alert(data.invalid);
                        } else if (data.error) {
                          navigate('/');
                        } else {
                          handleClose();
                          Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.success,
                            confirmButtonText: 'Close',
                          });
                          window.location.reload();
                        }
                        setSend(false);
                      });
                  })
                  .catch(() => {
                    navigate('/');
                  });
              }
            }}
            autoFocus
          >
            {send ? <i>sending</i> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <CircularProgress />
      ) : (
        <MaterialTable
          title="Homeclass Customers"
          columns={[
            {
              title: '#Customer ID',
              field: 'customerId',
            },

            {
              title: 'First Name',
              field: 'cus_first_name',
            },
            {
              title: 'Last Name',
              field: 'cus_last_name',
            },

            {
              title: 'Email',
              field: 'cus_email',
            },
            {
              title: 'Phone Number',
              field: 'cus_phone',
            },
            {
              title: 'Registration Platform',
              field: 'platform',
            },
            {
              title: 'Account officer',
              field: 'accountOfficer',
            },
            {
              title: 'Contacted/Remarks',
              field: 'contacted',
              render: (rowData) => {
                let option = null;
                if (rowData.contacted) {
                  option = (
                    <Button
                      variant="text"
                      color="info"
                      onClick={() => {
                        checkValidity()
                          .then(() => {
                            setReport(rowData.report);
                            handleClickOpen(rowData._id, `${rowData.cus_first_name} ${rowData.cus_last_name}`);
                          })
                          .catch(() => {
                            navigate('/');
                          });
                      }}
                    >
                      View/Edit Remark
                    </Button>
                  );
                } else {
                  option = (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        checkValidity()
                          .then(() => {
                            handleClickOpen(rowData._id, `${rowData.cus_first_name} ${rowData.cus_last_name}`);
                          })
                          .catch(() => {
                            navigate('/');
                          });
                      }}
                    >
                      Contacted
                    </Button>
                  );
                }

                return option;
              },
            },
            {},

            {
              title: 'Added Date',
              field: 'createdAt',
            },
            {
              title: 'Edit',
              field: 'edit',
              render: (rowData) =>
                rowData && (
                  <>
                    <Button variant="contained" color="warning">
                      Edit
                    </Button>
                  </>
                ),
            },
            {
              title: 'Suspend',
              field: 'suspend',
              render: (rowData) =>
                rowData && (
                  <>
                    <Button variant="contained" color="error">
                      Suspend
                    </Button>
                  </>
                ),
            },
          ]}
          data={customers}
          options={{
            pageSize: 5,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  );
}
