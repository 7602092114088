/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Box,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  Alert,
  Button,
} from '@mui/material';
import Swal from 'sweetalert2';
import { countries } from './countries';
import { BASE_URL } from '../../../../utils/constants';

export default function ProductSetup() {
  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const [alert, setAlert] = React.useState('error');
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser || !token) {
      navigate('/', { replace: true });
    }
  }, []);

  function responseFeedback(msg) {
    setMessage(msg);
    setOpen(true);
  }

  // state hooks

  const [serviceName, setServiceName] = React.useState('');
  const [processingTime, setProcessingTime] = React.useState('');
  const [numberOfPayment, setNumberOfPayment] = React.useState('');
  const [packageType, setPackagetype] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [slot, setSlot] = React.useState('');
  const [familyAllowed, setFamilyAllowed] = React.useState('');
  const [amount1, setAmount1] = React.useState('');
  const [amountToBeDeposited1, setAmountToBeDeposited1] = React.useState('');
  const [currency1, setCurrency1] = React.useState('');
  const [amount2, setAmount2] = React.useState('');
  const [amountToBeDeposited2, setAmountToBeDeposited2] = React.useState('');
  const [currency2, setCurrency2] = React.useState('');
  const [familyPayment, setFamilyPayment] = React.useState('');
  const [familyCurrency, setFamilyCurrency] = React.useState('');
  const [familyAmountToBeDeposited, setFamilyAmountToBeDeposited] = React.useState('');
  const [paymentRequiredForFamily, setPaymentRequiredForFamily] = React.useState(false);
  const [depositAllowed1, setDepositAllowed1] = React.useState(false);
  const [depositAllowed2, setDepositAllowed2] = React.useState(false);
  const [familyDepositAllowed, setFamilyDepositAllowed] = React.useState(false);
  const [familyIndividualPayment, setFamilyIndividualPayment] = React.useState(false);

  const handleSubmit = () => {
    setOpen(false);

    if (!serviceName || !processingTime || !numberOfPayment || !packageType) {
      responseFeedback('Service Name, processing time, Number of Payment, package type required');
    } else if (!familyAllowed) {
      responseFeedback('Please select if families are allowed to go');
    } else if (!amount1) {
      responseFeedback('Please enter the amount required for this package');
    } else if (!currency1) {
      responseFeedback('Please choose a currency required for conversion');
    } else if (numberOfPayment > 1 && !amount2) {
      responseFeedback('Please enter the second amount');
    } else if (numberOfPayment > 1 && !currency2) {
      responseFeedback('please choose a currency required for conversion for the second payment');
    } else if (!slot) {
      responseFeedback('Please Enter the number of slots available for this package');
    } else if (!country) {
      responseFeedback('Please Select a country assigned to this package');
    } else if (depositAllowed1 && !amountToBeDeposited1) {
      responseFeedback('Please Enter a deposit Amount');
    } else if (depositAllowed1 && amountToBeDeposited1 === amount1) {
      responseFeedback('Amount to be deposited cannot be equal to original amount');
    } else if (depositAllowed1 && +amountToBeDeposited1 > +amount1) {
      responseFeedback('Amount to be deposited cannot be greater than original amount');
    } else if (depositAllowed2 && !amountToBeDeposited2) {
      responseFeedback('Please Enter a deposit amount for the second payment');
    } else if (depositAllowed2 && +amountToBeDeposited2 >= +amount2) {
      responseFeedback('Amount to be deposited for second payment cannot be more than or equal to original amount');
    } else if (familyAllowed === 'Yes' && paymentRequiredForFamily && !familyPayment) {
      responseFeedback('Please Enter the amount required by family to pay');
    } else if (paymentRequiredForFamily && !familyCurrency) {
      responseFeedback('Please select currency for family payment conversion');
    } else if (paymentRequiredForFamily && familyDepositAllowed && !familyAmountToBeDeposited) {
      responseFeedback('Please Enter the amount to be deposited for family payment');
    } else if (paymentRequiredForFamily && +familyAmountToBeDeposited >= +familyPayment) {
      responseFeedback('Amount to be deposited for family member cannot be more than or equal to original amount');
    } else {
      setLoading(true);
      fetch(BASE_URL+'/create-new-package', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          serviceName,
          processingTime,
          numberOfPayment,
          packageType,
          country,
          slot,
          familyAllowed,
          amount1,
          amountToBeDeposited1,
          currency1,
          amount2,
          amountToBeDeposited2,
          currency2,
          familyPayment,
          familyCurrency,
          familyAmountToBeDeposited,
          paymentRequiredForFamily,
          depositAllowed1,
          depositAllowed2,
          familyDepositAllowed,
          familyIndividualPayment,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            navigate('/');
          } else if (data.invalid) {
            responseFeedback(data.invalid);
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: data.success,
              confirmButtonText: 'Close',
            });

            setServiceName('');
            setProcessingTime('');
            setNumberOfPayment('');
            setPackagetype('');
            setCountry('');
            setSlot('');
            setFamilyAllowed('');
            setAmount1('');
            setAmountToBeDeposited1('');
            setCurrency1('');
            setAmount2('');
            setAmountToBeDeposited2('');
            setCurrency2('');
            setFamilyPayment('');
            setFamilyCurrency('');
            setFamilyAmountToBeDeposited('');
            setPaymentRequiredForFamily(false);
            setDepositAllowed1(false);
            setDepositAllowed2(false);
            setFamilyDepositAllowed(false);
            setFamilyIndividualPayment(false);
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      {currentUser.privilege < 2 ? (
        <Alert severity="warning">You are not authorized to perform this operation</Alert>
      ) : (
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '30ch' },
          }}
        >
          {open ? (
            <Alert severity={alert} style={{ margin: '12px' }}>
              {message}
            </Alert>
          ) : null}
          <TextField
            fullWidth
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            type="text"
            label="Visa Type/Service Name"
            helperText="Enter a descriptive service name/visa type"
          />
          <TextField
            fullWidth
            value={processingTime}
            onChange={(e) => setProcessingTime(e.target.value)}
            select
            label="Processing Time"
            helperText={'select processing time'}
          >
            <MenuItem value={'10-15 Working Days'}>10-15 Working Days</MenuItem>
            <MenuItem value={'3-12 weeks'}>3-12 weeks</MenuItem>
            <MenuItem value={'2-4 months'}>2-4 months</MenuItem>
            <MenuItem value={'3-5 months'}>3-5 months</MenuItem>
            <MenuItem value={'4-6 months'}>4-6 months</MenuItem>
            <MenuItem value={'4-7 months'}>4-7 months</MenuItem>
            <MenuItem value={'6-18 months'}>6-18 months</MenuItem>
            <MenuItem value={'5 months- 1 year'}>5 months- 1 year</MenuItem>
          </TextField>
          <TextField
            fullWidth
            value={numberOfPayment}
            onChange={(e) => setNumberOfPayment(e.target.value)}
            select
            label="Number of Payments"
            helperText={'Enter number of payments required to facilitate this package'}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </TextField>
          <TextField
            fullWidth
            autoComplete="package Type"
            select
            label="Type of Package"
            value={packageType}
            onChange={(e) => setPackagetype(e.target.value)}
          >
            <MenuItem value={'Work'}>Work</MenuItem>
            <MenuItem value={'Tour'}>Tour</MenuItem>
            <MenuItem value={'Visit'}>Visit</MenuItem>
            <MenuItem value={'Residency'}>Residency</MenuItem>
            <MenuItem value={'Citizenship'}>Citizenship</MenuItem>
            <MenuItem value={'Education'}>Education</MenuItem>
          </TextField>
          <Autocomplete
            filterSelectedOptions
            id="country"
            name="country"
            options={countries}
            clearText="Nigeria"
            // Add the following props
            isOptionEqualToValue={(option, value) => option.label === value}
            value={country}
            renderInput={(params) => <TextField {...params} helperText={'search for country'} label="Country" />}
            onChange={(e, newValue) => {
              setCountry(newValue.label);
            }}
          />
          <TextField
            fullWidth
            value={slot}
            onChange={(e) => setSlot(e.target.value)}
            type="number"
            label="Number of Slots"
            helperText={'Enter the number of slots'}
          />

          <TextField
            fullWidth
            value={familyAllowed}
            onChange={(e) => setFamilyAllowed(e.target.value)}
            select
            label="Family Allowed To Go"
          >
            <MenuItem value={'Yes'}>Yes</MenuItem>
            <MenuItem value={'No'}>No</MenuItem>
          </TextField>

          <Divider />
          <Typography
            variant="h6"
            align="center"
            style={{ marginTop: '45px' }}
            textAlign={'center'}
            alignItems="center"
          >
            Payment Options
          </Typography>
          <hr />
          {/* Parameters 1: First Payment and its option */}
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '30ch' },
            }}
          >
            <TextField
              fullWidth
              autoComplete="product amount"
              type="number"
              label="Amount"
              value={amount1}
              onChange={(e) => setAmount1(e.target.value)}
              helperText={'Enter the amount for this package'}
            />

            <TextField
              fullWidth
              value={currency1}
              onChange={(e) => setCurrency1(e.target.value)}
              select
              label="Currency"
              helperText={'Select currency for this payment'}
            >
              <MenuItem value={'USD'}>US Dollars</MenuItem>
              <MenuItem value={'GBP'}>Pounds Sterling</MenuItem>
              <MenuItem value={'EUR'}>Euro</MenuItem>
              <MenuItem value={'NGN'}>Nigerian Naira</MenuItem>
            </TextField>
            <FormControlLabel
              control={<Checkbox onChange={(e) => setDepositAllowed1(e.target.checked)} checked={depositAllowed1} />}
              label="Deposit Allowed for this payment"
            />

            {depositAllowed1 ? (
              <TextField
                fullWidth
                value={amountToBeDeposited1}
                onChange={(e) => setAmountToBeDeposited1(e.target.value)}
                type="number"
                label="Amount To be Deposited"
                helperText={'Enter amount to be deposited'}
              />
            ) : null}
          </Box>

          {/* If Number of payment is 2, the following field should show */}
          {numberOfPayment === 2 ? (
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '30ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <Divider />
              <Typography
                variant="h6"
                align="center"
                style={{ marginTop: '45px' }}
                textAlign={'center'}
                alignItems="center"
              >
                Second Payment Options
              </Typography>
              <hr />
              <TextField
                fullWidth
                value={amount2}
                onChange={(e) => setAmount2(e.target.value)}
                type="number"
                label="Second Payment Amount"
                helperText={'Enter Amount for the second payment'}
              />

              <TextField
                fullWidth
                value={currency2}
                onChange={(e) => setCurrency2(e.target.value)}
                select
                label="Currency for Second Payment"
                helperText={'Select currency for the second payment'}
              >
                <MenuItem value={'USD'}>US Dollars</MenuItem>
                <MenuItem value={'GBP'}>Pounds Sterling</MenuItem>
                <MenuItem value={'EUR'}>Euro</MenuItem>
                <MenuItem value={'NGN'}>Nigerian Naira</MenuItem>
              </TextField>
              <FormControlLabel
                control={<Checkbox onChange={(e) => setDepositAllowed2(e.target.checked)} checked={depositAllowed2} />}
                label="Deposit Allowed for this Second payment"
              />

              {depositAllowed2 ? (
                <TextField
                  fullWidth
                  value={amountToBeDeposited2}
                  onChange={(e) => setAmountToBeDeposited2(e.target.value)}
                  type="number"
                  label="Amount To be Deposited"
                  helperText={'Enter amount to be deposited for the second payment'}
                />
              ) : null}
            </Box>
          ) : null}

          {/* Payment for families */}

          {familyAllowed === 'Yes' ? (
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1, width: '30ch' },
              }}
            >
              <Divider />
              <Typography
                variant="h6"
                align="center"
                style={{ marginTop: '45px' }}
                textAlign={'center'}
                alignItems="center"
              >
                Family Option Setup
              </Typography>
              <hr />
              <TextField
                fullWidth
                value={familyPayment}
                onChange={(e) => setFamilyPayment(e.target.value)}
                type="number"
                label="Amount required for family members to pay"
                helperText={'Enter amount required by family to pay'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setPaymentRequiredForFamily(e.target.checked)}
                    checked={paymentRequiredForFamily}
                  />
                }
                label="Payment Required For Families"
              />

              <TextField
                fullWidth
                value={familyCurrency}
                onChange={(e) => setFamilyCurrency(e.target.value)}
                select
                label="Currency for Family members"
                helperText={'Select currency required by family to pay'}
              >
                <MenuItem value={'USD'}>US Dollars</MenuItem>
                <MenuItem value={'GBP'}>Pounds Sterling</MenuItem>
                <MenuItem value={'EUR'}>Euro</MenuItem>
                <MenuItem value={'NGN'}>Nigerian Naira</MenuItem>
              </TextField>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setFamilyIndividualPayment(e.target.checked)}
                    checked={familyIndividualPayment}
                  />
                }
                label="Individual Payment for family members"
              />
              {familyIndividualPayment ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setFamilyDepositAllowed(e.target.checked)}
                      checked={familyDepositAllowed}
                    />
                  }
                  label="Deposit Allowed for Family members"
                />
              ) : null}

              {familyDepositAllowed ? (
                <TextField
                  fullWidth
                  value={familyAmountToBeDeposited}
                  onChange={(e) => setFamilyAmountToBeDeposited(e.target.value)}
                  type="number"
                  label="Amount To be Deposited for family members"
                />
              ) : null}
            </Box>
          ) : null}

          {/* Display error here */}
          {open ? (
            <Alert severity={alert} style={{ margin: '12px' }}>
              {message}
            </Alert>
          ) : null}
          <Button onClick={handleSubmit} fullWidth size="large" variant="contained" disabled={loading}>
            Create Veritas Visa
          </Button>
        </Box>
      )}
    </>
  );
}
