/* eslint-disable no-nested-ternary */
import React, { useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// material
import { Stack, Button, Container, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import { checkValidity } from '../layouts/dashboard/checkValidity';

export default function AccountManagement() {
  const currentUser = JSON.parse(localStorage.getItem('current_user')) || '';
  const navigate = useNavigate();


  const handleClickOpen = () => {
    navigate("/change-password")
  };


  useEffect(() => {
    checkValidity().catch(()=>{
      navigate("/")
    }) 
  }, []);
  return (
    <>
      <Page title="Account Management">
        <Container>
        
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Account Management
            </Typography>
            <Button onClick={handleClickOpen} variant="contained" to="#">
              Change Password
            </Button>
          </Stack>

          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="right">#Admin ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell align="right">Last Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Role</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {currentUser._id}
              </TableCell>
              <TableCell align="right">{currentUser.first_name}</TableCell>
              <TableCell align="right">{currentUser.last_name}</TableCell>
              <TableCell align="right">{currentUser.email}</TableCell>
              <TableCell align="right">{currentUser.role}</TableCell>
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
          
        </Container>
      </Page>
    </>
  );
}
