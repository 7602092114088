/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import PartnersForm from '../../../../sections/setup/pof/PartnersForm';
import ITFOptionForm from '../../../../sections/setup/pof/ITFOptionForm';
import { checkValidity } from '../../checkValidity';
import { BASE_URL } from '../../../../utils/constants';

export default function POFSetup() {
  const [expanded, setExpanded] = React.useState(false);
  const [component, setComponent] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const changeComponent = (parameter) => {
    setComponent(parameter);
    handleClickOpen();
  };

  const [options, setOptions] = useState([]);
  const [partners, setPartners] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const navigate = useNavigate();

  const fetchOptions = async () => {
    await fetch(BASE_URL+'/setup/itf-get-itf-option', {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
          setOpen(true);
        } else {
          setOpen(false);
          setOptions(data.options);
        }
      });
  };
  const fetchPartners = async () => {
    await fetch(BASE_URL+'/setup/get-partners', {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
          setOpen(true);
        } else {
          setOpen(false);
          setPartners(data.partners);
        }
      });
  };

  useEffect(() => {
    checkValidity()
      .then(() => {
        if (!currentUser) {
          navigate('/', { replace: true });
        } else {
          try {
            setLoading(true);
            fetchOptions();
            fetchPartners();
          } catch (e) {
            setMessage(e.message);
            setOpen(true);
          }
          setLoading(false);
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <div>
      {open ? (
        <Alert severity="error">{message}</Alert>
      ) : (
        <>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Partner's and ITF Option General Setup?"}</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{ marginBottom: '15px' }}>
                Perform neccessary setup for Int'l Travel Funds. This performanceof the system Parameters is dependent
                on this setup, ensure you set up appropriately.
              </DialogContentText>
              {
                // eslint-disable-next-line no-nested-ternary
                !component ? (
                  <Typography>No option selected</Typography>
                ) : component === 'partners' ? (
                  <PartnersForm />
                ) : component === 'pof' ? (
                  <ITFOptionForm />
                ) : null
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography sx={{ width: '33%', flexShrink: 0 }}>ITF Option</Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Set up ITF Option. e.g New Account, and it's rate
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                onClick={() => changeComponent('pof')}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                align="right"
              >
                Create New ITF Option
              </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <MaterialTable
                  title="ITF Options"
                  columns={[
                    {
                      title: 'ITF Option',
                      field: 'itfoption',
                    },

                    {
                      title: 'Rate',
                      field: 'rate',
                    },

                    {
                      title: 'Creation Date',
                      field: 'createdAt',
                    },
                    {
                      title: 'Updated Date',
                      field: 'updatedAt',
                    },
                    {
                      title: 'Edit',
                      field: 'Edit',
                      render: (rowData) =>
                        rowData && (
                          <>
                            <Button variant="contained" color="primary">
                              Edit
                            </Button>
                          </>
                        ),
                    },
                    {
                      title: 'Delete',
                      field: 'Delete',
                      render: (rowData) =>
                        rowData && (
                          <>
                            <Button variant="contained" color="error">
                              Delete
                            </Button>
                          </>
                        ),
                    },
                  ]}
                  data={options}
                  options={{
                    pageSize: 5,
                    sorting: true,
                    exportButton: true,
                    actionsColumnIndex: -1,
                  }}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Partners Set Up</Typography>
              <Typography sx={{ color: 'text.secondary' }}>Add, Remove, edit Partners</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                onClick={() => changeComponent('partners')}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                align="right"
              >
                Create New Partner
              </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <MaterialTable
                  title="ITF Partners"
                  columns={[
                    {
                      title: 'Partners Name',
                      field: 'partnersName',
                    },

                    {
                      title: 'Partners File',
                      field: 'partnersFile',
                      render: (rowData) => {
                        let value = '';
                        let option = null;
                        if (!rowData.partnersFile) {
                          value = '';
                          option = <Typography>No File found</Typography>;
                        } else {
                          value = rowData.partnersFile;
                          option = <a href={rowData.partnersFile}>Download Zip File</a>;
                        }
                        return option;
                      },
                    },

                    {
                      title: 'Creation Date',
                      field: 'createdAt',
                    },
                    {
                      title: 'Updated Date',
                      field: 'updatedAt',
                    },
                    {
                      title: 'Edit',
                      field: 'Edit',
                      render: (rowData) =>
                        rowData && (
                          <>
                            <Button variant="contained" color="primary">
                              Edit
                            </Button>
                          </>
                        ),
                    },
                    {
                      title: 'Delete',
                      field: 'Delete',
                      render: (rowData) =>
                        rowData && (
                          <>
                            <Button variant="contained" color="error">
                              Delete
                            </Button>
                          </>
                        ),
                    },
                  ]}
                  data={partners}
                  options={{
                    pageSize: 5,
                    sorting: true,
                    exportButton: true,
                    actionsColumnIndex: -1,
                  }}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
