/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';

import { Stack, Skeleton, Typography, Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { checkValidity } from '../../checkValidity';
import { BASE_URL } from '../../../../utils/constants';

export default function ManageVisaSetup() {
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const token = JSON.parse(localStorage.getItem('token_key')) || '';
  const currentUser = JSON.parse(localStorage.getItem('current_user')) || '';
  const navigate = useNavigate();

  useEffect(() => {
    checkValidity()
      .then(() => {
        fetch(BASE_URL+'/fetch-veritas-packages', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else {
              setPackages(data.packages);
            }
            setIsLoading(false);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  const packageAvailability = (packageId, status) => {
    if (currentUser.privilege < 2) {
      Swal.fire({
        icon: 'error',
        title: 'Access Denied!',
        text: 'You are not allowed to perform this operation',
        confirmButtonText: 'Close',
      });
    } else {
      checkValidity()
        .then(() => {
          if (window.confirm('This Package status will be changed. Do you still want to proceed?')) {
            fetch(BASE_URL+'/disable-package', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
              },
              body: JSON.stringify({
                packageId,
                status,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.error) {
                  navigate('/');
                } else if (data.invalid) {
                  Swal.fire({
                    icon: 'error',
                    title: 'System Error!',
                    text: data.invalid,
                    confirmButtonText: 'Close',
                  });
                } else {
                  Swal.fire({
                    icon: 'success',
                    title: 'Update successful',
                    text: data.success,
                    confirmButtonText: 'Close',
                  });
                  window.location.reload()
                }
              });
          }
        })
        .catch(() => {
          navigate('/');
        });
    }
  };
  return (
    <>
      {isLoading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" />
        </Stack>
      ) : (
        <MaterialTable
          title="Manage Veritas Visa setup"
          columns={[
            {
              title: 'Service Name',
              field: 'serviceName',
            },

            {
              title: 'Processing Time',
              field: 'processingTime',
            },

            {
              title: 'Number of Payment',
              field: 'numberOfPayment',
            },
            {
              title: 'Type of Visa Package',
              field: 'packageType',
            },
            {
              title: 'Country',
              field: 'country',
            },
            {
              title: 'Slot',
              field: 'slot',
            },
            {
              title: 'Visa Amount',
              field: 'amount1',
              render: (rowData) => {
                return (
                  <Grid>
                    <Typography>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.currency1 || 'NGN',
                      }).format(rowData.amount1)}
                    </Typography>
                    <Typography>
                      (
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.currency1 || 'NGN',
                      }).format(rowData.amountToBeDeposited1) || 'No deposit required'}
                      )
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              title: 'Other Payment Amount',
              field: 'amount2',
              render: (rowData) => {
                return (
                  <Grid>
                    <Typography>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.currency2 || 'NGN',
                      }).format(rowData.amount2)}
                    </Typography>
                    <Typography>
                      (
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.currency2 || 'NGN',
                      }).format(rowData.amountToBeDeposited2) || 'No deposit required'}
                      )
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              title: 'Family Member Payments',
              field: 'amount2',
              render: (rowData) => {
                return (
                  <Grid>
                    <Typography>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.familyCurrency || 'NGN',
                      }).format(rowData.familyPayment)}
                    </Typography>
                    <Typography>
                      (
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: rowData.familyCurrency || 'NGN',
                      }).format(rowData.familyAmountToBeDeposited) || 'No deposit required'}
                      )
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              title: 'Date Created',
              field: 'createdAt',
              render: (rowData) => {
                return (
                  <Typography>
                    {new Date(rowData.createdAt).toISOString().substring(0, 19).replace('T', ' ')}
                  </Typography>
                );
              },
            },
            {
              title: 'Date Updated',
              field: 'updatedAt',
              render: (rowData) => {
                return (
                  <Typography>
                    {new Date(rowData.updatedAt).toISOString().substring(0, 19).replace('T', ' ')}
                  </Typography>
                );
              },
            },
            {
              title: 'Availability',
              field: 'availability',
              render: (rowData) => {
                let option = null;
                if (rowData.availability) {
                  option = (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => packageAvailability(rowData._id, rowData.availability)}
                    >
                      Disable Availability
                    </Button>
                  );
                } else {
                  option = (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => packageAvailability(rowData._id, rowData.availability)}
                    >
                      Enable Availability
                    </Button>
                  );
                }

                return option;
              },
            },
            {
              title: 'Edit Package',
              field: 'edit',
              render: (rowData) => {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      checkValidity()
                        .then(() => {
                          if (currentUser.privilege < 2) {
                            Swal.fire({
                              icon: 'error',
                              title: 'Access Denied!',
                              text: 'You are not allowed to perform this operation',
                              confirmButtonText: 'Close',
                            });
                          } else {
                            localStorage.setItem('editPackage', JSON.stringify(rowData))
                            navigate(`/dashboard/package/edit/${rowData.serviceName}`);
                          }
                        })
                        .catch(() => {
                          navigate('/');
                        });
                    }}
                  >
                    Edit Package
                  </Button>
                );
              },
            },
            // {
            //   title: 'Delete Package',
            //   field: 'Delete',
            //   render: (rowData) => {
            //     return (
            //       <Button variant="contained" color="error">
            //         Delete Package
            //       </Button>
            //     );
            //   },
            // },
          ]}
          data={packages}
          options={{
            pageSize: 5,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  );
}
