/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
  Alert,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { checkValidity } from '../../checkValidity';
import { BASE_URL } from '../../../../utils/constants';

export default function Coupons() {
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState('');
  const [alert, setAlert] = React.useState(false);
  const [couponValue, setCouponValue] = React.useState('');
  const [loadCoupons, setLoadCoupons] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [coupons, setCoupons] = React.useState([]);

  const token = JSON.parse(localStorage.getItem('token_key')) || '';
  const navigate = useNavigate();

  const handleClickOpen = () => {
    checkValidity()
      .then(() => {
        setOpen(true);
      })
      .catch(() => {
        navigate('/');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConversion = () => {
    checkValidity()
      .then(() => {
        // handle conversion
        setAlert(false);
        setLoad(true);
        fetch(BASE_URL+'/create-coupon', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            couponCode,
            couponValue,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else if (data.invalid) {
              setMessage(data.invalid);
              setAlert(true);
            } else {
              setCouponCode('');
              setCouponValue('');
              handleClose();
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: data.success,
                confirmButtonText: 'Close',
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            setMessage(error.message);
            setAlert(true);
          });
      })
      .catch(() => {
        navigate('/');
      });
  };

  useEffect(() => {
    checkValidity()
      .then(() => {
        setLoadCoupons(true);
        fetch(BASE_URL+'/fetch-coupons', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setCoupons(data.coupons);
            setLoadCoupons(false);
          })
          .catch((error) => {
            window.alert(error.message);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Use this form to set Coupon Codes'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Use this form to set Coupon codes. This means if the coupon code already exists in the record,
            the value will be updated, if not a new one will be setup.
          </DialogContentText>

          <Box
            component="form"
            style={{ margin: '10px' }}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '90%' },
            }}
            noValidate
            autoComplete="off"
          >
            {alert ? <Alert severity="warning">{message}</Alert> : null}
            <TextField
              fullWidth
              label="Enter Coupon Code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              helperText={'Enter a coupon code here'}

            />
              

            <TextField
              label="Coupon rate"
              type={'number'}
              value={couponValue}
              onChange={(e) => setCouponValue(e.target.value)}
              fullWidth
              helperText="Enter the corresponding amount that will be deducted if the coupon code is applied"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConversion} disabled={load} autoFocus>
            Save Coupon
          </Button>
        </DialogActions>
      </Dialog>
      <Button style={{ margin: '23px 15px' }} variant="contained" onClick={handleClickOpen}>
        Manage Coupon codes
      </Button>
      {loadCoupons ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={118} />
        </Stack>
      ) : (
        <MaterialTable
          title="Manage Coupons"
          columns={[
            {
              title: 'Coupon Codes',
              field: 'couponCode',
            },

            {
              title: 'Coupon rates',
              field: 'couponValue',
              render: (rowData)=>{
                 return <Typography>{new Intl.NumberFormat().format(
                    rowData.couponValue
                  )}</Typography>
              }
            },

            {
              title: 'Creation Date',
              field: 'createdAt',
            },
            {
              title: 'Updated Date',
              field: 'updatedAt',
            },
            {
              title: 'Coupon Availability',
              field: 'couponAvailabilty',
              render: (rowData)=>{
                let option = null;

                if(rowData.couponValidity){
                  option = <Button variant='contained' color='warning' onClick={()=>{
                    checkValidity().then(()=>{
                      if(window.confirm("Are you sure you want to disable this coupon?")){
                        fetch(BASE_URL+"/coupon-toggle",{
                          method:"POST",
                          headers:{
                            "Content-Type":"application/json",
                            "x-access-token": token
                          },
                          body: JSON.stringify({
                            couponCode: rowData.couponCode,
                            change: false
                          })
                        }).then(res=>res.json())
                        .then(data=>{
                          if(data.error){
                            navigate("/")
                          }else if(data.success){
                            Swal.fire({
                              icon: 'success',
                              title: 'Success!',
                              text: `${data.success}. Refresh your page to see effect`,
                              confirmButtonText: 'Close',
                            });
                          }
                        })
                      }
                    }).catch(()=>{
                      navigate("/")
                    })
                  }}>Disable Coupon</Button>
                }else{
                  option = <Button variant='contained' color='success' onClick={()=>{
                    checkValidity().then(()=>{
                      if(window.confirm("Are you sure you want to enable this coupon?")){
                        fetch(BASE_URL+"/coupon-toggle",{
                          method:"POST",
                          headers:{
                            "Content-Type":"application/json",
                            "x-access-token": token
                          },
                          body: JSON.stringify({
                            couponCode: rowData.couponCode,
                            change: true
                          })
                        }).then(res=>res.json())
                        .then(data=>{
                          if(data.error){
                            navigate("/")
                          }else if(data.success){
                            Swal.fire({
                              icon: 'success',
                              title: 'Success!',
                              text: `${data.success}. Refresh your page to see effect`,
                              confirmButtonText: 'Close',
                            });
                          }
                        })
                      }
                    }).catch(()=>{
                      navigate("/")
                    })
                  }}>Enable Coupon</Button>
                }
                 return option
              }
            },
            {
              title: 'Delete Coupon',
              field: 'couponDelete',
              render: (rowData)=>{
                 return <Button variant='contained' color='error' onClick={()=>{
                   checkValidity().then(()=>{
                    if(window.confirm("Are you sure you want to delete this coupon?")){
                      fetch(BASE_URL+"/delete-coupon",{
                      method:"POST",
                      headers:{
                        "Content-Type":"application/json",
                        "x-access-token": token
                      },
                      body: JSON.stringify({
                        couponCode: rowData.couponCode,
                        change: true
                      })
                    }).then(res=>res.json())
                    .then(data=>{
                      if(data.error){
                        navigate("/")
                      }else if(data.success){
                        Swal.fire({
                          icon: 'success',
                          title: 'Success!',
                          text: `${data.success}. Refresh your page to see effect`,
                          confirmButtonText: 'Close',
                        });
                      }
                    })
                    }
                   }).catch(()=>{
                     navigate("/")
                   })
                 }}>Delete Coupon</Button>
              }
            },
            
          ]}
          data={coupons}
          options={{
            pageSize: 5,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  );
}
