/* eslint-disable */
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Link, useNavigate } from 'react-router-dom';
import { checkValidity } from '../../checkValidity';
import { BASE_URL } from '../../../../utils/constants';

export default function CompleteTransactions() {
  const [transactions, setTransactions] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const navigate = useNavigate();
  useEffect(() => {
    checkValidity()
      .then(() => {
        if (!currentUser) {
          navigate('/', { replace: true });
        } else {
          setLoading(true);
          try {
            fetch(BASE_URL+'/applications/billing/paid', {
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.error) {
                  setMessage(data.error);
                  setOpen(true);
                  setLoading(false);
                } else if (data.billings) {
                  setLoading(false);
                  setTransactions(data.billings);
                } else {
                  setTransactions([]);
                  setLoading(false);
                }
              });
          } catch (e) {
            setOpen(true);
            setMessage(e.message);
            setLoading(false);
          }
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Completed Transactions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {open ? <Alert severity="error">{message}</Alert> : null}
            {loading ? (
              <CircularProgress />
            ) : (
              <MaterialTable
                title="ITF Completed Transactions"
                columns={[
                  {
                    title: '#Application ID',
                    field: 'applicationId',
                    render: (rowData) => (
                      <Link to={`/dashboard/application/${rowData._id}`}>{rowData.applicationId}</Link>
                    ),
                  },
                  {
                    title: '#Customer ID',
                    field: 'customerId',
                    render: (rowData) => <Link to={`/dashboard/user/${rowData.customerId}`}>{rowData.customerId}</Link>,
                  },
                  {
                    title: 'Loan Amount',
                    field: 'loanAmount',
                    render: (rowData) =>
                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                        Number(rowData.loanAmount)
                      ),
                  },
                  {
                    title: 'ITF Option',
                    field: 'itfOption',
                  },

                  {
                    title: 'Rate',
                    field: 'rate',
                  },
                  {
                    title: 'Interest To Pay',
                    field: 'interestToPay',
                    render: (rowData) =>
                      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                        Number(rowData.interestToPay)
                      ),
                  },
                  {
                    title: 'Transaction Date',
                    field: 'createdAt',
                  },
                ]}
                data={transactions}
                options={{
                  pageSize: 5,
                  sorting: true,
                  exportButton: true,
                  actionsColumnIndex: -1,
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
