/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Alert, CircularProgress, Button, Typography } from '@mui/material';

import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../utils/constants';

export default function FetchStaff() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  useEffect(() => {
    if (!currentUser) {
      navigate('/', { replace: true });
    } else {
      setLoading(true);
      try {
        fetch(BASE_URL+'/accounts/staff', {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setMessage(data.error);
              setOpen(true);
              setLoading(false);
            } else {
              setLoading(false);
              setStaff(data.staff);
            }
          });
      } catch (e) {
        setOpen(true);
        setMessage(e.message);
        setLoading(false);
      }
    }
  }, []);
  return (
    <>
      {open ? <Alert severity="error">{message}</Alert> : null}

      {loading ? (
        <CircularProgress />
      ) : (
        <MaterialTable
          title="ITF Transactions"
          columns={[
            {
              title: '#ID',
              field: '_id',
            },

            {
              title: 'First Name',
              field: 'first_name',
            },
            {
              title: 'Last Name',
              field: 'last_name',
            },

            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Role',
              field: 'role',
            },
            {
              title: 'Privilege',
              field: 'privilege',
              render: (rowData) => {
                let value = '';
                if (rowData.privilege === 1) {
                  value = 'Admin 1';
                } else if (rowData.privilege === 2) {
                  value = 'Admin 2';
                } else {
                  value = 'Super Admin';
                }
                return <Typography>{value}</Typography>;
              },
            },

            {
              title: 'Added Date',
              field: 'createdAt',
            },

            {
              title: 'Delete',
              field: 'delete',
              render: (rowData) => {
                let option = null;
                if (currentUser) {
                  if (currentUser._id === rowData._id) {
                    option = null;
                  }else if(currentUser.privilege < 3){
                    option = <Typography>Not authorized for deletion</Typography>
                  }
                  else {
                    option = (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                         if (
                            confirm(
                              `Are you sure you want to delete ${rowData.first_name} Account permanently? This action is irreversible`
                            )
                          ) {
                            fetch(BASE_URL+"/admin/delete",{
                              method:"POST",
                              headers:{
                                "Content-Type":"application/json",
                                'x-access-token': token,
                              },
                              body: JSON.stringify({
                                adminId: rowData._id,
                                currentAdminId: currentUser._id
                              })
                            }).then(res=>res.json())
                            .then(data=>{
                              if(data.error){
                                alert(data.error)
                              }else{
                                alert(data.success)
                                window.location.reload()
                              }
                            })
                          }
                        }}
                      >
                        Delete
                      </Button>
                    );
                  }
                  return option;
                }
              },
            },
          ]}
          data={staff}
          options={{
            pageSize: 5,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  );
}
