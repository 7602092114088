/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
// material
import { Alert, Backdrop, CircularProgress, IconButton, InputAdornment, Button, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// component
import { BASE_URL } from '../../../utils/constants';
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------

export default function AuthenticationForm() {
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem('token_key'));
  const currentUser = JSON.parse(localStorage.getItem('current_user'));

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('error');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isError, setIsError] = useState(false);

  const ChangeCodeSchema = Yup.object().shape({
    code: Yup.number('Code must be a number').required('Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: ChangeCodeSchema,
    onSubmit: () => {
      setIsVerifying(true);
      setIsError(false);
      fetch(BASE_URL+'/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          adminId: currentUser._id,
          code: formik.values.code,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            navigate('/');
          } else if (data.invalid) {
            setMessage(data.invalid);
            setAlert('error');
            setIsError(true);
          } else {
            localStorage.setItem('isAuthenticaticated', JSON.stringify(true))
            navigate('/dashboard/app');
          }

          setIsVerifying(false);
        })
        .catch((error) => {
          setMessage('Could not establish connect');
          setAlert('error');
          setIsError(true);
          setIsVerifying(false);
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (!token && !currentUser) {
      navigate('/');
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {isVerifying ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isVerifying}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}

        <>
          <Stack spacing={3}>
            {isError ? (
              <Alert severity={alert} style={{ margin: '10px', width: '80%', height: '100px' }}>
                {message} <br />
                <Button variant="contained" color="primary" onClick={()=>navigate("/")}>
                  Go Back to login
                </Button>
              </Alert>
            ) : null}
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'number' : 'password'}
              label="Authentication code"
              {...getFieldProps('code')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Authenticate
          </LoadingButton>
        </>
      </Form>
    </FormikProvider>
  );
}
