/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Button, Container, Stack, Typography, Skeleton } from '@mui/material';
import { BASE_URL } from '../utils/constants';
import { checkValidity } from '../layouts/dashboard/checkValidity';

// components
import Page from '../components/Page';
import EditVeritas from '../layouts/dashboard/components/veritas/EditVeritas';


export default function EditOverview() {
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const token = JSON.parse(localStorage.getItem('token_key'));

  const { service } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    checkValidity()
      .then(() => {
        if (!service) {
          navigate('/dashboard/app');
        } else {
          fetch(BASE_URL+'/get-package-by-name', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
            },
            body: JSON.stringify({ serviceName: service }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                navigate('/');
              } else if (data.invalid) {
                Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: data.invalid,
                  confirmButtonText: 'Close',
                });
              } else {
                setPackages(data.packages)
              }
              setIsLoading(false)
            });
        }
      })
      .catch(() => {
        navigate('/');
      });
  });

  return (
    <>
      <Page title={`Veritas: ${service}`}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Edit Overview - {service}
            </Typography>
          </Stack>

          {isLoading ? (
            <Stack spacing={1}>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" />
            </Stack>
          ) : (
            <EditVeritas packages={packages} />
          )}
        </Container>
      </Page>
    </>
  );
}
