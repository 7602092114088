/* eslint-disable */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BASE_URL } from '../../../utils/constants';


// ----------------------------------------------------------------------

export default function ITFOptionForm() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const ITFOptionSchema = Yup.object().shape({
    option: Yup.string("Please enter a valid ITF option").required("ITF option is required"),
    rate: Yup.number('The rate should be a number').required('Rate is required'),
  });

  const formik = useFormik({
    initialValues: {
      option: '',
      rate: null,
    },
    validationSchema: ITFOptionSchema,
    onSubmit: async () => {
      setLoading(true);
      setOpen(false);

      fetch(BASE_URL+"/setup/create-itf-option",{
          method: "POST",
          headers:{
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
              option: formik.values.option,
              rate: formik.values.rate
          })
      }).then(res=>res.json())
      .then(data=>{
          if(data.error){
            setMessage(data.error);
            setOpen(true);
            setLoading(false);
          }else{
              setLoading(false)
              // eslint-disable-next-line no-alert
              alert(data.success)
              window.location.reload()
              
          }
      })

      
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {open ? (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {message}
          </Alert>
        ) : null}
        {loading ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="itf-option"
            type="text"
            label="ITF Option"
            {...getFieldProps('option')}
            error={Boolean(touched.option && errors.option)}
            helperText={touched.option && errors.option}
          />

          <TextField
            fullWidth
            autoComplete="rate"
            type="number"
            label="ITF Rate"
            {...getFieldProps('rate')}
            error={Boolean(touched.rate && errors.rate)}
            helperText={touched.rate && errors.rate}
          />
        </Stack>

        <LoadingButton
          onClick={handleSubmit}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Create New ITF Option
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
