import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import TransactionOverview from './pages/TransactionOverview';
import GeneralSetup from './pages/GeneralSetup';
import Staff from './pages/Staff';
import AccountManagement from './pages/AccountManagement';
import CheckEmail from './pages/CheckEmail';
import ChangePassword from './pages/ChangePassword';
import Veritas from './pages/Veritas';
import UserOverview from './pages/UserOverview';
import EditOverview from './pages/EditOverview'
import Authentication from './pages/Authentication';
import ProductInformationOverview from './pages/ProductInformationOverview';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        {path:'account', element: <AccountManagement/>},
        { path: 'staff', element: <Staff /> },
        { path: 'set-up', element: <GeneralSetup /> },
        { path: 'products/proof-of-funds', element: <Products /> },
        { path: 'application/:id', element: <TransactionOverview /> },
        {path:"products/veritas", element:<Veritas />},
        {path:"products/product-information/:product", element: <ProductInformationOverview />},
        {path:"user/:id", element:<UserOverview />},
        {path:"package/edit/:service", element:<EditOverview />}
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'authentication', element: <Authentication /> },
        { path: '404', element: <NotFound /> },
        {path: 'change-password', element: <CheckEmail />},
        {path: 'account/verification/:token', element: <ChangePassword />},
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    
  ]);
}
