/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
} from '@mui/material';
import { BASE_URL } from '../utils/constants';
import { checkValidity } from '../layouts/dashboard/checkValidity';

import Page from '../components/Page';


export default function UserOverview() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    checkValidity()
      .then(() => {
        fetch(BASE_URL+'/fetch-customer-name', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerId: id }),
        })
          .then((res) => res.json())
          .then((data) => {
            setUser(data.customer);

            setLoading(false);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <Page title="Customer | Customer Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customer Details
          </Typography>
        </Stack>

        {loading ? (
          <Stack spacing={1}>
            <Skeleton variant="text" />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
            <Skeleton variant="rectangular" width={210} height={118} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" />
          </Stack>
        ) : (
          <Box sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, mt: 3 }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>{id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer First Name</TableCell>
                    <TableCell style={{textTransform:"capitalize"}}>{user.cus_first_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer Last Name</TableCell>
                    <TableCell style={{textTransform:"capitalize"}}>{user.cus_last_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer Email</TableCell>
                    <TableCell style={{textTransform:"capitalize"}}>{user.cus_email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer Phone Number</TableCell>
                    <TableCell>{user.cus_phone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Container>
    </Page>
  );
}
