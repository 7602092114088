/* eslint-disable */
import { BASE_URL } from "../../utils/constants";

export const checkValidity = ()=>{
    const token = JSON.parse(localStorage.getItem('token_key')) || '';
  const currentUser = JSON.parse(localStorage.getItem('current_user')) || '';
  const isAuthenticaticated = JSON.parse(localStorage.getItem('isAuthenticaticated')) || false
    

  return new Promise((resolve, reject)=>{
    if(!token || !currentUser || !isAuthenticaticated || isAuthenticaticated === null){
        reject()
    }else{
        fetch(BASE_URL+"/check",{
            headers:{
                "Content-Type":"application/json",
                "x-access-token": token
            }
        }).then(res=>res.json())
        .then(data=>{
            if(data.error){
                reject()
            }else{
                resolve()
            }
        })
    }
  })
}