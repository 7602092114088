import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        adminDetail:  null,
        token: null
    },
    reducers: {
        updateAdmin: (state, action)=>{
            state.adminDetail = action.payload.adminDetail
        }
    }
})

export const {updateAdmin} = adminSlice.actions
export default adminSlice.reducer

