/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable */
import {useEffect, useState} from 'react'
// material
import {
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../utils/constants';
import FetchCustomers from '../layouts/dashboard/components/FetchCustomers';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { checkValidity } from '../layouts/dashboard/checkValidity';





export default function User() {
  
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('current_user'))
  const token = JSON.parse(localStorage.getItem('token_key'));

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(!currentUser){
      navigate("/",{replace: true})
    }
  }, [currentUser, navigate])

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={()=>{
            if(confirm("You will be redirected to the client side to perform this operation")){
              window.location.href = "https://itf.homeclassgroup.com/register"
            }
          }}>
            New User
          </Button>
          {
            currentUser.privilege >=2?<Button variant="contained" disabled={loading} onClick={()=>{
              checkValidity().then(()=>{
                fetch(BASE_URL+"/assign-officer",{
                  method: "POST",
                  headers:{
                    "Content-Type":"application/json",
                    "x-access-token": token
                  }
                }).then(res=>res.json())
                .then(data=>{
                  if(data.completed){
                    window.alert(data.completed)
                  }else{
                    window.alert("Could not assign officers due to system error")
                  }
                })
              }).catch(()=>{
                navigate("/")
              })
            }}>
            {loading?"Assigning...": "Assign Officers"}
          </Button>:null
          }
        </Stack>

        <FetchCustomers />
      </Container>
    </Page>
  );
}
