/* eslint-disable */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import storage from '../../../hooks/firebase/config';
import { BASE_URL } from '../../../utils/constants';

// ----------------------------------------------------------------------

export default function PartnersForm() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const PartnersSchema = Yup.object().shape({
    name: Yup.string("Please enter a valid partner's name").required("Partner's name is required"),
    file: Yup.mixed('Please choose a file').required('A file is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      file: null,
    },
    validationSchema: PartnersSchema,
    onSubmit: async () => {
      setLoading(true);
      setOpen(false);

      if(!formik.values.file.includes('zip')){
        setMessage("Only zip file is required");
        setOpen(true);
        setLoading(false);
      }else{
        const fileName = new Date().getTime() + formik.values.name;
      const storageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(storageRef, formik.values.file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${  progress  }% done`);
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              console.log('error uploading file');
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          setMessage('Could not upload file. If this persists, please contact the support');
          console.log(error);
          setMessage(error);
          setOpen(true);
          setLoading(false);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(async (Url) => {
            // save and update db;

            await fetch(BASE_URL+'/setup/create-partners', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name: formik.values.name,
                file: Url,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.error) {
                  setMessage(data.error);
                  setOpen(true);
                  setLoading(false);
                } else {
                  setLoading(false);
                  // eslint-disable-next-line no-alert
                  alert("Partner successfully created")
                  window.location.reload()
                }
              }).catch(error=>{
                console.log(error)
                setMessage("Couldn't connect to server");
                  setOpen(true);
                  setLoading(false);
              })
          });
        }
      );
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {open ? (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {message}
          </Alert>
        ) : null}
        {loading ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="partner-name"
            type="text"
            label="Partner's Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            autoComplete="file"
            type="file"
            label="Choose a Zip file"
            {...getFieldProps('file')}
            error={Boolean(touched.file && errors.file)}
            helperText={touched.file && errors.file}
          />
        </Stack>

        <LoadingButton
          onClick={handleSubmit}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Create New Partner
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
