import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductSetup from './ProductSetup';
import ManageVisaSetup from './ManageVisaSetup';
import Coupons from './Coupons';
import ConversionRates from './ConversionRates';

export default function VeritasSetup() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Product Set Up</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Set Up a new visa package</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProductSetup />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Visa Package</Typography>
          <Typography sx={{ color: 'text.secondary' }}>View and Manage veritas products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ManageVisaSetup />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Conversion Set Up</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Set up conversion rates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ConversionRates />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Coupon set up</Typography>
          <Typography sx={{ color: 'text.secondary' }}>View and Manage Coupons</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Coupons />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
