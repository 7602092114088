import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBPeHx8tlQDgQl6n9km1hpYbVQ6vpITvRE",
  authDomain: "proof-of-funds-9f6ee.firebaseapp.com",
  projectId: "proof-of-funds-9f6ee",
  storageBucket: "proof-of-funds-9f6ee.appspot.com",
  messagingSenderId: "684504989456",
  appId: "1:684504989456:web:ddd45d3dd85311294c6f95"
};
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebaseApp);

export default storage
