/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, { useEffect, useState } from 'react';

// material
import { Stack, Button, Container, Typography, Tab, Box, Skeleton, Grid, Alert } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { BASE_URL } from '../utils/constants';
import Page from '../components/Page';
import { checkValidity } from '../layouts/dashboard/checkValidity';


export default function Veritas() {
  const currentUser = JSON.parse(localStorage.getItem('current_user')) || '';
  const token = JSON.parse(localStorage.getItem('token_key'));
  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');
  const [applications, setApplications] = useState([]);
  const [awaiting, setAwaiting] = useState([]);
  const [paid, setPaid] = useState([]);
  const [incomplete, setInComplete] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    checkValidity()
      .then(async () => {
        // fetch pending

        await fetch(BASE_URL+'/fetch-veritas-applications', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else {
              setApplications(data.application);
            }
            setIsLoading(false);
          });

        // fetch awaiting

        setIsLoading(true);
        await fetch(BASE_URL+'/fetch-awaiting-confirmation', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else {
              setAwaiting(data.application);
            }
            setIsLoading(false);
          });

        // fetch paid

        setIsLoading(true);
        await fetch(BASE_URL+'/fetch-paid-veritas-application', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else {
              setPaid(data.application);
            }
            setIsLoading(false);
          });

        // fetch incomplete payment

        setIsLoading(true);
        await fetch(BASE_URL+'/fetch-incomplete-veritas-application', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            
            if (data.error) {
              navigate('/');
            } else {
              setInComplete(data.application);
            }
            setIsLoading(false);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  const approvePayment = (applicationId, customerId) => {
    checkValidity()
      .then(() => {
        if(currentUser.privilege > 1){
          if(window.confirm("Are you sure you want to approve this payment?")){
            fetch(BASE_URL+'/approve-veritas-payment', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
              },
              body: JSON.stringify({
                applicationId,
                customerId,
                adminId: currentUser._id || '',
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.error) {
                  navigate('/');
                } else if (data.invalid) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.invalid,
                    confirmButtonText: 'Close',
                  });
                } else {
                  Swal.fire({
                    icon: 'success',
                    title: 'Transaction Successful!',
                    text: data.success,
                    confirmButtonText: 'Close',
                  });
    
                  window.location.reload();
                }
              });
          }
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Access Denied!',
            text: "You are not authorized to perform this action",
            confirmButtonText: 'Close',
          });
        }
      })
      .catch(() => {
        navigate('/');
      });
  };

  const checkSlots = (serviceName) => {
    checkValidity()
      .then(() => {
        fetch(BASE_URL+'/check-remaining-slot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            serviceName,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else {
              window.alert(`Slot Assigned: ${data.slot}\nSlot Used: ${data.used}\nRemaining slots: ${data.remaining}`);
            }
          });
      })
      .catch(() => {
        navigate('/');
      });
  };
  return (
    <>
      <Page title="Veritas Visa | Processing Portal">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Veritas Visa Management Portal
            </Typography>
          </Stack>

          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Pending Applications" value="1" />
                  <Tab label="Awaiting Confirmation" value="2" />
                  <Tab label="Completed Payments" value="3" />
                  <Tab label="Payments not completed" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {isLoading ? (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" />
                  </Stack>
                ) : (
                  <>
                    <Alert severity="info" style={{ width: '100%', margin: '20px' }}>
                      This tab show pending applications that havent been paid at all. No action is required here,
                      except if you want to call the customer to inform him/her that no slots booked due to non-payment
                    </Alert>
                    <MaterialTable
                      title="Veritas Application"
                      columns={[
                        {
                          title: 'Service Name',
                          field: 'serviceName',
                        },
                        {
                          title: 'Customer Name',
                          field: 'customerId',
                          render: (rowData) => {
                            return <Link to ={`/dashboard/user/${rowData.customerId}`}>{rowData.customerId}</Link>
                          },
                        },

                        {
                          title: 'Number of Payment',
                          field: 'numberOfPayment',
                        },
                        {
                          title: 'Type of Visa Package',
                          field: 'packageType',
                        },
                        {
                          title: 'Country',
                          field: 'country',
                        },
                        {
                          title: 'Visa Amount',
                          field: 'amount1',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Other Payment Amount',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Family Member Payments',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyPayment)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountToPay * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountRemaining * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title:"Number of Family",
                          field:"numberOfFamily",
                          render: (rowData)=>{
                            let option = null
                            if(rowData.numberOfFamily === 0){
                              option = <Typography>No Family member taken</Typography>
                            }else{
                              option = <Typography>{rowData.numberOfFamily}</Typography>
                            }
                            return option
                          }
                        },
                        {
                          title: 'Date Created',
                          field: 'createdAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.createdAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Date Updated',
                          field: 'updatedAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.updatedAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                      ]}
                      data={applications}
                      options={{
                        pageSize: applications.length < 5 ? 5 : 10,
                        sorting: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                      }}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value="2">
                {isLoading ? (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" />
                  </Stack>
                ) : (
                  <>
                    <Alert severity="info" style={{ width: '100%', margin: '20px' }}>
                      This tab show payments the application that payment proofs have been uploaded by customers but are
                      not yet confirmed. No slots are booked yet until you confirm this payment, hence an action to
                      confirm payment is required
                    </Alert>
                    <MaterialTable
                      title="Veritas Application"
                      columns={[
                        {
                          title: 'Service Name',
                          field: 'serviceName',
                        },
                        {
                          title: 'Customer Name',
                          field: 'customerId',
                          render: (rowData) => {
                            return <Link to ={`/dashboard/user/${rowData.customerId}`}>{rowData.customerId}</Link>
                          },
                        },

                        {
                          title: 'Number of Payment',
                          field: 'numberOfPayment',
                        },
                        {
                          title: 'Type of Visa Package',
                          field: 'packageType',
                        },
                        {
                          title: 'Country',
                          field: 'country',
                        },
                        {
                          title: 'Payment proof',
                          field: 'proofUrl',
                          render: (rowData) => {
                            let option = null;
                            if (!rowData.proofUrl) {
                              option = <Typography>No proof uploaded yet</Typography>;
                            } else {
                              option = <a href={rowData.proofUrl}>Download Payment Proof</a>;
                            }

                            return option;
                          },
                        },
                        {
                          title: 'Balance proof',
                          field: 'proofUrl2',
                          render: (rowData) => {
                            let option = null;
                            if (!rowData.proofUrl2) {
                              option = <Typography>No proof uploaded yet</Typography>;
                            } else {
                              option = <a href={rowData.proofUrl2}>Download Payment Balance Proof</a>;
                            }

                            return option;
                          },
                        },
                        {
                          title: 'Conversion Rate for Payment',
                          field: 'currencyRates',
                          render: (rowData) => {
                            let option = null;

                            if (rowData.currencyRates1 !== null) {
                              option = (
                                <Grid>
                                  <Typography>USD Rate:{rowData.currencyRates.USD || ''}</Typography>
                                  <Typography>GBP Rate:{rowData.currencyRates.GBP || ''}</Typography>
                                  <Typography>Euro Rate:{rowData.currencyRates.Euro || ''}</Typography>
                                </Grid>
                              );
                            } else {
                              option = <Typography>No Conversion rate applied</Typography>;
                            }
                            return option;
                          },
                        },
                        {
                          title: 'Conversion Rate for Balance Payment',
                          field: 'currencyRates2',
                          render: (rowData) => {
                            let option = null;

                            if (rowData.currencyRates2 != null) {
                              option = (
                                <Grid>
                                  <Typography>USD Rate:{rowData.currencyRates2.USD || ''}</Typography>
                                  <Typography>GBP Rate:{rowData.currencyRates2.GBP || ''}</Typography>
                                  <Typography>Euro Rate:{rowData.currencyRates2.Euro || ''}</Typography>
                                </Grid>
                              );
                            } else {
                              option = <Typography>No Conversion rate applied</Typography>;
                            }
                            return option;
                          },
                        },
                        {
                          title: 'Visa Amount',
                          field: 'amount1',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Other Payment Amount',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Family Member Payments',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyPayment)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountToPay * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountRemaining * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title:"Number of Family",
                          field:"numberOfFamily",
                          render: (rowData)=>{
                            let option = null
                            if(rowData.numberOfFamily === 0){
                              option = <Typography>No Family member taken</Typography>
                            }else{
                              option = <Typography>{rowData.numberOfFamily}</Typography>
                            }
                            return option
                          }
                        },
                        {
                          title: 'Date Created',
                          field: 'createdAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.createdAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Date Updated',
                          field: 'updatedAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.updatedAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Approve Payment',
                          field: 'approval',
                          render: (rowData) => {
                            let option = null;

                            if (
                              rowData.amount1Status === 1 ||
                              rowData.amount2Status === 1 ||
                              rowData.familyPaymentStatus === 1 
                            ) {
                              option = (
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => approvePayment(rowData._id, rowData.customerId)}
                                >
                                  Approve Payment
                                </Button>
                              );
                            } else {
                              option = (
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => approvePayment(rowData._id, rowData.customerId)}
                                >
                                  Approve Payment Balance
                                </Button>
                              );
                            }

                            return option;
                          },
                        },
                        {
                          title: 'Slot Remaining',
                          field: 'slot',
                          render: (rowData) => {
                            return (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => checkSlots(rowData.serviceName)}
                              >
                                Check Remaining slots
                              </Button>
                            );
                          },
                        },
                      ]}
                      data={awaiting}
                      options={{
                        pageSize: awaiting.length < 5 ? 5 : 10,
                        sorting: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                      }}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value="3">
                {isLoading ? (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" />
                  </Stack>
                ) : (
                  <>
                    <Alert severity="info" style={{ width: '100%', margin: '20px' }}>
                      This tab show payments that all payments have been completed and approved. No action required.
                      Usually data displayed are not much since most packages allows deposit. To see payments that have
                      been deposited, go to tab 4. No action is required
                    </Alert>
                    <MaterialTable
                      title="Veritas Application"
                      columns={[
                        {
                          title: 'Service Name',
                          field: 'serviceName',
                        },
                        {
                          title: 'Customer Name',
                          field: 'customerId',
                          render: (rowData) => {
                            return <Link to ={`/dashboard/user/${rowData.customerId}`}>{rowData.customerId}</Link>
                          },
                        },

                        {
                          title: 'Number of Payment',
                          field: 'numberOfPayment',
                        },
                        {
                          title: 'Type of Visa Package',
                          field: 'packageType',
                        },
                        {
                          title: 'Country',
                          field: 'country',
                        },
                        {
                          title: 'Payment proof',
                          field: 'proofUrl',
                          render: (rowData) => {
                            let option = null;
                            if (!rowData.proofUrl) {
                              option = <Typography>No proof uploaded yet</Typography>;
                            } else {
                              option = <a href={rowData.proofUrl}>Download Payment Proof</a>;
                            }

                            return option;
                          },
                        },
                        {
                          title: 'Balance proof',
                          field: 'proofUrl2',
                          render: (rowData) => {
                            let option = null;
                            if (!rowData.proofUrl2) {
                              option = <Typography>No proof uploaded yet</Typography>;
                            } else {
                              option = <a href={rowData.proofUrl2}>Download Payment Balance Proof</a>;
                            }

                            return option;
                          },
                        },
                        {
                          title: 'Conversion Rate for Payment',
                          field: 'currencyRates',
                          render: (rowData) => {
                            let option = null;

                            if (rowData.currencyRates1 !== null) {
                              option = (
                                <Grid>
                                  <Typography>USD Rate:{rowData.currencyRates.USD || ''}</Typography>
                                  <Typography>GBP Rate:{rowData.currencyRates.GBP || ''}</Typography>
                                  <Typography>Euro Rate:{rowData.currencyRates.Euro || ''}</Typography>
                                </Grid>
                              );
                            } else {
                              option = <Typography>No Conversion rate applied</Typography>;
                            }
                            return option;
                          },
                        },
                        {
                          title: 'Conversion Rate for Balance Payment',
                          field: 'currencyRates2',
                          render: (rowData) => {
                            let option = null;

                            if (rowData.currencyRates2 != null) {
                              option = (
                                <Grid>
                                  <Typography>USD Rate:{rowData.currencyRates2.USD || ''}</Typography>
                                  <Typography>GBP Rate:{rowData.currencyRates2.GBP || ''}</Typography>
                                  <Typography>Euro Rate:{rowData.currencyRates2.Euro || ''}</Typography>
                                </Grid>
                              );
                            } else {
                              option = <Typography>No Conversion rate applied</Typography>;
                            }
                            return option;
                          },
                        },
                        {
                          title: 'Visa Amount',
                          field: 'amount1',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Other Payment Amount',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Family Member Payments',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyPayment)}
                                </Typography>
                                <Typography>
                                  (Amount claim paid:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountToPay * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount claim balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountRemaining * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title:"Number of Family",
                          field:"numberOfFamily",
                          render: (rowData)=>{
                            let option = null
                            if(rowData.numberOfFamily === 0){
                              option = <Typography>No Family member taken</Typography>
                            }else{
                              option = <Typography>{rowData.numberOfFamily}</Typography>
                            }
                            return option
                          }
                        },
                        {
                          title: 'Date Created',
                          field: 'createdAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.createdAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Date Updated',
                          field: 'updatedAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.updatedAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Approve Payment',
                          field: 'approval',
                          render: (rowData) => {
                            return <Alert severity="success">Payment completed</Alert>;
                          },
                        },
                        {
                          title: 'Slot Remaining',
                          field: 'slot',
                          render: (rowData) => {
                            return (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => checkSlots(rowData.serviceName)}
                              >
                                Check Remaining slots
                              </Button>
                            );
                          },
                        },
                      ]}
                      data={paid}
                      options={{
                        pageSize: paid.length < 5 ? 5 : 10,
                        sorting: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                      }}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value="4">
                {isLoading ? (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" />
                  </Stack>
                ) : (
                  <>
                    <Alert severity="info" style={{ width: '100%', margin: '20px' }}>
                      This tab show payments that have been paid but requires balance. The balance could be that of the
                      Visa Amount, Other Payments of Payment for family members. The customer hasn't uploaded any
                      payment proof for the balance, once the payment proof has been uploaded by the customer, it will
                      be moved to awaiting confirmation tab
                    </Alert>
                    <MaterialTable
                      title="Veritas Application"
                      columns={[
                        {
                          title: 'Service Name',
                          field: 'serviceName',
                        },
                        {
                          title: 'Customer Name',
                          field: 'customerId',
                          render: (rowData) => {
                            return <Link to ={`/dashboard/user/${rowData.customerId}`}>{rowData.customerId}</Link>
                          },
                        },

                        {
                          title: 'Number of Payment',
                          field: 'numberOfPayment',
                        },
                        {
                          title: 'Type of Visa Package',
                          field: 'packageType',
                        },
                        {
                          title: 'Country',
                          field: 'country',
                        },
                        {
                          title: 'Visa Amount',
                          field: 'amount1',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency1 || 'NGN',
                                  }).format(rowData.amount1Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Other Payment Amount',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2ToPay) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.currency2 || 'NGN',
                                  }).format(rowData.amount2Remaining) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title: 'Family Member Payments',
                          field: 'amount2',
                          render: (rowData) => {
                            return (
                              <Grid>
                                <Typography>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyPayment)}
                                </Typography>
                                <Typography>
                                  (Amount To Pay:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountToPay * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                                <Typography>
                                  (Amount To Balance:
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: rowData.familyCurrency || 'NGN',
                                  }).format(rowData.familyAmountRemaining * rowData.numberOfFamily) || 'No deposit required'}
                                  )
                                </Typography>
                              </Grid>
                            );
                          },
                        },
                        {
                          title:"Number of Family",
                          field:"numberOfFamily",
                          render: (rowData)=>{
                            let option = null
                            if(rowData.numberOfFamily === 0){
                              option = <Typography>No Family member taken</Typography>
                            }else{
                              option = <Typography>{rowData.numberOfFamily}</Typography>
                            }
                            return option
                          }
                        },
                        {
                          title: 'Date Created',
                          field: 'createdAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.createdAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: 'Date Updated',
                          field: 'updatedAt',
                          render: (rowData) => {
                            return (
                              <Typography>
                                {new Date(rowData.updatedAt).toISOString().substring(0, 19).replace('T', ' ')}
                              </Typography>
                            );
                          },
                        },
                      ]}
                      data={incomplete}
                      options={{
                        pageSize: incomplete.length < 5 ? 5 : 10,
                        sorting: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                      }}
                    />
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Page>
    </>
  );
}
