/* eslint-disable */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppTrafficBySite, AppWidgetSummary } from '../sections/@dashboard/app';
import { checkValidity } from '../layouts/dashboard/checkValidity';
import { BASE_URL } from '../utils/constants';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('current_user'));

  const [customerNumber, setCustomerNumber] = useState(0);
  const [staffNumber, setStaffNumber] = useState(0);
  const [ticket, setTicket] = useState([]);

  const fetchCustomerCount = async () => {
    await fetch(BASE_URL+'/accounts/customer/count', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/', { replace: true });
        } else {
          setCustomerNumber(data.count);
        }
      });
  };
  const fetchStaffCount = async () => {
    await fetch(BASE_URL+'/accounts/staff/count', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/', { replace: true });
        } else {
          setStaffNumber(data.count);
        }
      });
  };

  const fetchTickets = () => {
    fetch(BASE_URL+'/support/get-tickets', {
      header: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/', { replace: true });
        } else {
          setTicket(data.ticket);
        }
      });
  };

  useEffect(() => {
    checkValidity()
      .then(() => {
        if (!currentUser) {
          navigate('/', { replace: true });
        } else {
          fetchCustomerCount();
          fetchStaffCount();
          fetchTickets();
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Homeclass Staff" total={staffNumber} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Homeclass Users"
              total={customerNumber}
              color="info"
              icon={'ant-design:apple-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Homeclass Products" total={2} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Support Center"
              total={ticket.length}
              color="error"
              icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Homeclass Support Tickets"
              list={[
                {
                  name: 'Proof of Funds',
                  value: 0,
                  icon: <Iconify icon={'eva:lock-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Veritas Visa',
                  value: 0,
                  icon: <Iconify icon={'eva:lock-fill'} color="#DF3E30" width={32} height={32} />,
                },
                // {
                //   name: 'Product 3',
                //   value: 0,
                //   icon: <Iconify icon={'eva:lock-fill'} color="#006097" width={32} height={32} />,
                // },
                // {
                //   name: 'Product 4',
                //   value: 0,
                //   icon: <Iconify icon={'eva:lock-fill'} color="#1C9CEA" width={32} height={32} />,
                // },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTrafficBySite
              title="Homeclass Available Products"
              list={[
                {
                  name: 'Proof of Funds',
                  value: 'Active',
                  icon: <Iconify icon={'eva:lock-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Veritas Visa',
                  value: 'Active',
                  icon: <Iconify icon={'eva:lock-fill'} color="#DF3E30" width={32} height={32} />,
                },
                // {
                //   name: 'Product 3',
                //   value: 'Not Active',
                //   icon: <Iconify icon={'eva:lock-fill'} color="#006097" width={32} height={32} />,
                // },
                // {
                //   name: 'Product 4',
                //   value: 'Not Active',
                //   icon: <Iconify icon={'eva:lock-fill'} color="#1C9CEA" width={32} height={32} />,
                // },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
