/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
  Alert,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import { checkValidity } from '../../checkValidity';
import { BASE_URL } from '../../../../utils/constants';

export default function ConversionRates() {
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [currency, setCurrency] = React.useState('');
  const [alert, setAlert] = React.useState(false);
  const [rate, setRate] = React.useState('');
  const [loadRate, setLoadRate] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [conversions, setConversions] = React.useState([]);

  const token = JSON.parse(localStorage.getItem('token_key')) || '';
  const navigate = useNavigate();

  const handleClickOpen = () => {
    checkValidity()
      .then(() => {
        setOpen(true);
      })
      .catch(() => {
        navigate('/');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConversion = () => {
    checkValidity()
      .then(() => {
        // handle conversion
        setAlert(false);
        setLoad(true);
        fetch(BASE_URL+'/conversion-rates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            currency,
            rate,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else if (data.invalid) {
              setMessage(data.invalid);
              setAlert(true);
            } else {
              setCurrency('');
              setRate('');
              handleClose();
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: data.success,
                confirmButtonText: 'Close',
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            setMessage(error.message);
            setAlert(true);
          });
      })
      .catch(() => {
        navigate('/');
      });
  };

  useEffect(() => {
    checkValidity()
      .then(() => {
        setLoadRate(true);
        fetch(BASE_URL+'/fetch-conversion-rate', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setConversions(data.rates);
            setLoadRate(false);
          })
          .catch((error) => {
            window.alert(error.message);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Use this form to set Conversion rate'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Use this form to set Conversion rate. This means if the conversion rate value already exists in the record,
            the rate will be updated, if not a new one will be setup.
          </DialogContentText>

          <Box
            component="form"
            style={{ margin: '10px' }}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '90%' },
            }}
            noValidate
            autoComplete="off"
          >
            {alert ? <Alert severity="warning">{message}</Alert> : null}
            <TextField
              fullWidth
              select
              label="Currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              helperText={'Select your desired Currency'}
            >
              <MenuItem value={'USD'}>US Dollars</MenuItem>
              <MenuItem value={'GBP'}>Pounds Sterling</MenuItem>
              <MenuItem value={'EUR'}>Euro</MenuItem>
            </TextField>

            <TextField
              label="Conversion rate"
              type={'number'}
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              fullWidth
              helperText="Enter the equivalent rate to Nigerian Naira"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConversion} disabled={load} autoFocus>
            Save Rate
          </Button>
        </DialogActions>
      </Dialog>
      <Button style={{ margin: '23px 15px' }} variant="contained" onClick={handleClickOpen}>
        Manage Conversion Rates
      </Button>
      {loadRate ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={118} />
        </Stack>
      ) : (
        <MaterialTable
          title="Manage Conversion Rates"
          columns={[
            {
              title: 'Currency',
              field: 'currency',
            },

            {
              title: 'Rate',
              field: 'rate',
              render: (rowData)=>{
                 return <Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                    rowData.rate
                  )}</Typography>
              }
            },

            {
              title: 'Creation Date',
              field: 'createdAt',
            },
            {
              title: 'Updated Date',
              field: 'updatedAt',
            }
          ]}
          data={conversions}
          options={{
            pageSize: 5,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  );
}
