/* eslint-disable */
import { Button, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import storage from '../../../../hooks/firebase/config';
import { BASE_URL } from '../../../../utils/constants';
import { checkValidity } from '../../checkValidity';

const NewProductInformation = () => {
  const INITIAL_VALUE = {
    productTitle: '',
    productDescription: '',
    productCategory: '',
    emailSubject: '',
  };
  const [value, setValue] = useState(INITIAL_VALUE);
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // const isValidUrl = (urlString) => {
  //   try {
  //     return Boolean(new URL(urlString));
  //   } catch (e) {
  //     return false;
  //   }
  // };

  const handleSubmit = async () => {
    if (!value.productTitle || !value.productCategory || !value.productDescription || !file || !value.emailSubject) {
      alert('All fields are required');
    } else {
      setLoading(true);
      checkValidity()
        .then(async () => {
          // save the file to firebase storage

          const fileName = new Date().getTime() + '_' + file.name;
          const storageRef = ref(storage, fileName);

          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              console.log(error);
              window.alert('Failed to upload file');
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log(downloadURL);
                // save record to database

                await fetch(BASE_URL + '/create-product-info', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                  },
                  body: JSON.stringify({
                    productTitle: value.productTitle,
                    productCategory: value.productCategory,
                    productDescription: value.productCategory,
                    emailSubject: value.emailSubject,
                    productFileUrl: downloadURL,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.invalid) {
                      alert(data.invalid);
                    } else if (data.exists) {
                      alert(data.exists);
                    } else if (data.saved) {
                      alert(data.saved);
                    }

                    setValue({
                      productTitle: '',
                      productCategory: '',
                      productDescription: '',
                    });
                    setFile(null);
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    alert('An error occured while saving the product information');
                    setLoading(false);
                  });
              });
            }
          );
        })
        .catch(() => {
          navigate('/');
        });
    }
  };
  return (
    <div style={{ marginTop: '10px' }}>
      <TextField
        fullWidth
        value={value.productTitle}
        name="productTitle"
        onChange={(e) => handleChange(e)}
        type="text"
        label="Product Title"
        style={{ marginBottom: '10px' }}
        helperText="Give a clear title... This is what is displayed to potential customers"
      />
      <TextField
        fullWidth
        value={value.emailSubject}
        name="emailSubject"
        onChange={(e) => handleChange(e)}
        type="text"
        label="Email Subject for this product"
        style={{ marginBottom: '10px' }}
        helperText="This will serve as the email subject for the product content"
      />
      <TextField
        fullWidth
        value={value.productCategory}
        name="productCategory"
        onChange={(e) => handleChange(e)}
        select
        label="Product Category"
        helperText={'select product category'}
      >
        <MenuItem>...Select product category...</MenuItem>
        <MenuItem value={'ITF'}>Proof of funds</MenuItem>
        <MenuItem value={'Visa Packages'}>Visa Packages</MenuItem>
        <MenuItem value="Study">Study</MenuItem>
        <MenuItem value="BOI">Bank of Industry Funding</MenuItem>
        <MenuItem value="Partnership">Become Our Partner</MenuItem>
      </TextField>
      <TextField
        fullWidth
        value={value.productDescription}
        name="productDescription"
        multiline
        rows={4}
        onChange={(e) => handleChange(e)}
        type="text"
        label="Product Description"
        style={{ marginBottom: '10px' }}
        helperText="Give a brief description..."
      />

      <TextField
        fullWidth
        onChange={(e) => setFile(e.target.files[0])}
        type="file"
        label="Product Content file"
        style={{ marginBottom: '10px' }}
        helperText="choose a docx file only"
      />
      <Button onClick={handleSubmit} disabled={loading} variant="contained" color="primary" size="small">
        {loading ? <i>creating...</i> : 'Create New Product Information'}
      </Button>
    </div>
  );
};

export default NewProductInformation;
