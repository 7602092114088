/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Button, Skeleton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../utils/constants';
import { checkValidity } from '../../checkValidity';

const ManageProductInformation = () => {
  const [information, setInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = JSON.parse(localStorage.getItem('token_key'));
  const navigate = useNavigate();

  useEffect(() => {
    checkValidity()
      .then(() => {
        fetch(BASE_URL + '/get-product-info', {
          headers: {
            'COntent-Type': 'application/json',
            'x-access-token': token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setInformation(data.productInfo);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            alert('Failed to fetch');
            setLoading(false);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <div>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" />
        </Stack>
      ) : (
        <MaterialTable
          title="Manage Product Information"
          columns={[
            {
              title: 'Product Title',
              field: 'productTitle',
            },

            {
              title: 'Product Category',
              field: 'productCategory',
            },

            {
              title: 'Product Description',
              field: 'productDescription',
            },

            {
              title: 'Content Url',
              field: 'productFileUrl',
              render: (rowData) => {
                return (
                  <a href={rowData.productFileUrl} target="_blank">
                    {rowData.productFileUrl}
                  </a>
                );
              },
            },

            {
              title: 'Edit Product Information',
              field: 'edit',
              render: (rowData) => {
                return (
                  <Button variant="contained" color="success" size="small">
                    Edit Product
                  </Button>
                );
              },
            },
            {
              title: 'Toggle Availability',
              field: 'Delete',
              render: (rowData) => {
                let option = null;
                if (rowData.availability) {
                  option = (
                    <Button variant="contained" color="primary" size="small">
                      Disable Availabiltity
                    </Button>
                  );
                } else {
                  option = (
                    <Button variant="contained" color="primary" size="small">
                      Enable Availability
                    </Button>
                  );
                }

                return option;
              },
            },
            {
              title: 'Delete Product',
              field: 'Delete',
              render: (rowData) => {
                return (
                  <Button variant="contained" color="error" size="small">
                    Delete Package
                  </Button>
                );
              },
            },
          ]}
          data={information}
          options={{
            pageSize: 10,
            sorting: true,
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </div>
  );
};

export default ManageProductInformation;
