/* eslint-disable no-alert */
/* eslint-disable */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { BASE_URL } from '../../../utils/constants';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Please select a role for the staff'),
    privilege: Yup.number().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      privilege: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      fetch(BASE_URL + '/admin/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: formik.values.firstName,
          last_name: formik.values.lastName,
          email: formik.values.email.toLowerCase(),
          role: formik.values.role,
          password: formik.values.password,
          privilege: formik.values.privilege,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          formik.setSubmitting(false);
          if (data.error) {
            alert(data.error);
          } else {
            alert(data.success);
            window.location.reload();
          }
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="role"
            select
            type="text"
            label="Admin Role"
            {...getFieldProps('role')}
            error={Boolean(touched.role && errors.role)}
            helperText={touched.role && errors.role}
          >
            <MenuItem value={'Communication'}>Communication</MenuItem>
            <MenuItem value={'Administration'}>Administration</MenuItem>
            <MenuItem value={'Others'}>Others</MenuItem>
          </TextField>

          <TextField
            fullWidth
            autoComplete="role"
            select
            type="number"
            label="Admin Privilege"
            {...getFieldProps('privilege')}
            error={Boolean(touched.privilege && errors.privilege)}
            helperText={touched.privilege && errors.privilege}
          >
            <MenuItem value={1}>Communication privileges</MenuItem>
            <MenuItem value={2}>Administrative privileges</MenuItem>
          </TextField>

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
