// material
import { Container, Typography, Box, InputLabel, Select, MenuItem, FormControl, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// components
import POFSetup from '../layouts/dashboard/components/pof/POFSetup';
import Page from '../components/Page';
import VeritasSetup from '../layouts/dashboard/components/veritas/VeritasSetup';
import { checkValidity } from '../layouts/dashboard/checkValidity';
import ProductInformationSetup from '../layouts/dashboard/components/products-information/ProductsOverviewSetup';

// ----------------------------------------------------------------------

export default function GeneralSetup() {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('current_user'));

  const [product, setProduct] = useState('');

  const handleChange = (event) => {
    setProduct(event.target.value);
  };

  useEffect(() => {
    checkValidity().catch(() => {
      navigate('/');
    });
  }, []);

  function getProduct() {
    let viewOption = null;

    if (product === 'ITF') {
      viewOption = <POFSetup />;
    } else if (product === 'veritas') {
      viewOption = <VeritasSetup />;
    } else if (product === 'Product Information') {
      viewOption = <ProductInformationSetup />;
    } else {
      viewOption = <Typography variant="h4">No Set up selected</Typography>;
    }

    return viewOption;
  }
  return (
    <Page title="Set Up: General Product setup">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Setting - General Setup
        </Typography>

        {currentUser.privilege < 2 ? (
          <Alert severity="warning">You are not authorized to access this page</Alert>
        ) : (
          <Box sx={{ minWidth: 120 }} style={{ marginBottom: '15px' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">...Select Product...</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={product}
                label="...Select Product..."
                onChange={handleChange}
              >
                <MenuItem value={'ITF'}>Proof of Funds(ITF)</MenuItem>
                <MenuItem value={'veritas'}>Veritas Visa</MenuItem>
                <MenuItem value={'Product Information'}>Product Information Set up</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {getProduct()}
      </Container>
    </Page>
  );
}
