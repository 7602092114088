/* eslint-disable */
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Alert,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BASE_URL } from '../../../utils/constants';


// ----------------------------------------------------------------------

export default function CheckEmailForm() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('error');
  const [open, setOpen] = useState(false);

  const CheckEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: CheckEmailSchema,
    onSubmit: () => {
      setIsLoading(true);
      setOpen(false)

      fetch(BASE_URL+'/verify-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formik.values.email.toLowerCase()
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setMessage(data.error);
            setAlert('error');
            setOpen(true);
          } else {
            setMessage(data.success)
            setAlert('success')
            setOpen(true)
            formik.handleReset()
          }
          setIsLoading(false);
          formik.setSubmitting(false)
        })
        .catch((error) => {
          console.log(error);
          setMessage("Unable to connect to sever... Please check your connection");
          setAlert('error');
          setOpen(true);
          setIsLoading(false);
          formik.setSubmitting(false)
        });
      
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {open ? (
          <Alert severity={alert} style={{ margin: '10px' }}>
            {message}
          </Alert>
        ) : null}

        {isLoading ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Verify Email
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
