/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable */
// material
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
// components
import MaterialTable from 'material-table';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Page from '../components/Page';
import { BASE_URL } from '../utils/constants';

// ----------------------------------------------------------------------

export default function TransactionOverview() {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [partners, setPartners] = useState([]);
  const [load, setLoad] = useState(false);
  const [billId, setBillId] = useState('');
  const [tranch, setTranch] = useState(undefined);
  const [approvalMessage, setApprovalMessage] = useState('');
  const [openApprovalAlert, setOpenApprovalAlert] = useState(false);

  // Dialog Box props

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [partner, setPartner] = useState('');

  const handleChange = (event) => {
    setPartner(event.target.value);
  };

  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const token = JSON.parse(localStorage.getItem('token_key'));

  const fetchApplicationDetail = async () => {
    setLoading(true);
    await fetch(BASE_URL+'/applications/get-detail-by-id', {
      method: 'POST',
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
          setOpen(true);
        } else {
          setOpen(false);
          setDetails(data.application);
        }
      });
  };

  const fetchApplicationTransaction = async () => {
    await fetch(BASE_URL+'/applications/billing/get-detail-by-id', {
      method: 'POST',
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
          setOpen(true);
        } else {
          setOpen(false);
          setTransaction(data.application);
        }
        setLoading(false);
      });
  };

  const approveTransaction = async () => {
    if (!billId) {
      setMessage('An error has been encountered');
      setOpen(true);
      setOpenDialog(false);
    } else if (!currentUser) {
      setMessage('You are not logged In properly... Please logout and login again');
      setOpen(true);
      setOpenDialog(false);
    } else if (!partner) {
      alert('Please assign a partner');
    } else {
      setLoad(true);
      await fetch(BASE_URL+'/applications/billing/approval', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          billingId: billId,
          adminId: currentUser._id,
          partner,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setApprovalMessage(data.error);
            setOpenApprovalAlert(true);
          } else {
            // eslint-disable-next-line no-alert
            alert(data.success);
            window.location.reload();
          }
          setLoad(false);
        });
    }
  };

  const fetchPartners = async (billingId, billingTranch) => {
    setLoad(true);
    handleClickOpenDialog();
    setBillId(billingId);
    setTranch(billingTranch);
    await fetch(BASE_URL+'/setup/get-partners', {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
          setOpen(true);
        } else {
          setOpen(false);
          setPartners(data.partners);
        }
      });
    setLoad(false);
  };
  useEffect(() => {
    if (!currentUser) {
      navigate('/', { replace: true });
    } else {
      try {
        fetchApplicationDetail();
        fetchApplicationTransaction();
      } catch (e) {
        setOpen(true);
        setMessage(e.message);
      }
    }
  }, []);
  return (
    <Page title="Proof of Funds | Transaction Overview">
      <Container>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Approval Portal for Int'l Travel Fund Transactions"}</DialogTitle>
          <DialogContent>
            {openApprovalAlert ? <Alert severity="info">{approvalMessage}</Alert> : null}
            {
              // eslint-disable-next-line no-nested-ternary
              load ? (
                <CircularProgress />
              ) : tranch <= 1 ? (
                <>
                  <DialogContentText id="alert-dialog-description">
                    Select Patner to attach with this. After which you can Click on Approve. Please note that this
                    action cannot be reversed once approved.
                  </DialogContentText>
                  <Box sx={{ minWidth: 120 }}>
                    {partners.length === 0 && !load ? (
                      <Typography>No Partners available for selection</Typography>
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Partners</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={partner}
                          label="...Select Partner..."
                          onChange={handleChange}
                        >
                          {
                            // eslint-disable-next-line arrow-body-style
                            partners.map((item) => {
                              return (
                                <MenuItem key={item.partnersName} value={item.partnersName}>
                                  {item.partnersName}
                                </MenuItem>
                              );
                            })
                          }
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </>
              ) : (
                <Alert severity="info">
                  A Partner has already been attached to this project... Click on Proceed to continue
                </Alert>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={approveTransaction} variant="contained" color="success">
              Approve Transaction
            </Button>
            <Button onClick={handleClose} variant="text" color="warning">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h4" sx={{ mb: 5 }}>
          POF - Transaction Overview
        </Typography>
        {open ? <Alert severity="error">{message}</Alert> : null}
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <MaterialTable
              title="Application Details"
              columns={[
                {
                  title: '#Application ID',
                  field: 'applicationId',
                },

                {
                  title: 'Loan Amount',
                  field: 'loanAmount',
                  render: (rowData) =>
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                      Number(rowData.loanAmount)
                    ),
                },

                {
                  title: 'ITF Option',
                  field: 'itfOption',
                },

                {
                  title: 'Rate',
                  field: 'rate',
                },
                {
                  title: 'Tenure',
                  field: 'tenure',
                },
                {
                  title: 'Interest To Pay',
                  field: 'interestToPay',
                  render: (rowData) =>
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                      Number(rowData.interestToPay)
                    ),
                },

                {
                  title: 'Application Status',
                  field: 'applicationStatus',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.applicationStatus === 0) {
                      value = 'Pending';
                    } else if (rowData.applicationStatus === 1) {
                      value = 'In-Processing';
                    } else {
                      value = 'Completed';
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Transaction Date',
                  field: 'createdAt',
                },
                {
                  title: 'Updated Date',
                  field: 'updatedAt',
                },
                {
                  title: 'Download Document',
                  field: 'docs',
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Button variant="contained" color="primary" onClick={()=>{
                          fetch(BASE_URL+"/download-document",{
                            method:"POST",
                            headers:{
                              "Content-Type":"application/json",
                              'x-access-token': token,
                            },
                            body: JSON.stringify({
                              applicationId: rowData.applicationId
                            })
                          }).then(res=>res.json())
                          .then(data=>{
                            if(data.error){
                              alert(data.error)
                            }else{
                              alert("Document available for download, click OK to download")
                              window.location.href = data.documentUrl
                            }
                          })
                        }}>
                          Download Transaction Document
                        </Button>
                      </>
                    ),
                },
                {
                  title: 'Remove Document',
                  field: 'docs',
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Button variant="contained" color="error" onClick={()=>{
                          if(confirm("Are you sure you want to remove this document? Action not reversible")){
                            fetch(BASE_URL+"/remove-document",{
                            method:"POST",
                            headers:{
                              "Content-Type":"application/json",
                              'x-access-token': token,
                            },
                            body: JSON.stringify({
                              applicationId: rowData.applicationId
                            })
                          }).then(res=>res.json())
                          .then(data=>{
                            if(data.error){
                              alert(data.error)
                            }else{
                             alert(data.success)
                            }
                          })
                          }
                        }}>
                          Remove Transaction Document
                        </Button>
                      </>
                    ),
                },
              ]}
              data={details}
              options={{
                pageSize: 1,
                exportButton: true,
              }}
            />
            <Divider />
            <MaterialTable
              title="Application Payment Details"
              columns={[
                {
                  title: '#Application ID',
                  field: 'applicationId',
                },

                {
                  title: 'Loan Amount',
                  field: 'loanAmount',
                  render: (rowData) =>
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                      Number(rowData.loanAmount)
                    ),
                },
                {
                  title: 'Payment Tranch',
                  field: 'paymentTranch',
                },
                {
                  title: 'ITF Option',
                  field: 'itfOption',
                },

                {
                  title: 'Rate',
                  field: 'rate',
                },
                {
                  title: 'Interest To Pay',
                  field: 'interestToPay',
                  render: (rowData) =>
                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                      Number(rowData.interestToPay)
                    ),
                },
                
                {
                  title: 'Active Status',
                  field: 'isActive',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.isActive) {
                      value = 'Due for Payment';
                    } else {
                      value = 'Not yet due for payment';
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Active Date',
                  field: 'isActiveDate',
                },
                {
                  title: 'Payment Status',
                  field: 'paymentStatus',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.paymentStatus === 0) {
                      value = 'Pending';
                    } else if (rowData.paymentStatus === 1) {
                      value = 'Awaiting Confirmation';
                    } else {
                      value = 'Confirmed and Paid';
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Extension',
                  field: 'extensionRequest',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.extensionRequest) {
                      value = 'Requested for Extension';
                    } else {
                      value = 'No request made';
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Extension Timeframe',
                  field: 'extensionTimeFrame',
                  render: (rowData) => {
                    let value = '';
                    if (!rowData.extensionTimeFrame) {
                      value = 'No request';
                    } else {
                      value = rowData.extensionTimeFrame;
                    }
                    return <Typography>{value}</Typography>;
                  },
                },

                {
                  title: 'Complete Status',
                  field: 'completeStatus',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.completeStatus === 0) {
                      value = 'Pending';
                    } else if (rowData.completeStatus === 1) {
                      value = 'Process Ongoing';
                    } else {
                      value = 'Confirmed and Completed';
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Payment Proof',
                  field: 'paymentProof',
                  render: (rowData) => {
                    let value = '';
                    let option = null;
                    if (!rowData.paymentProof) {
                      value = '';
                      option = <Typography>No Payment Proof yet</Typography>;
                    } else {
                      value = rowData.paymentProof;
                      option = <a href={value}>Download Proof</a>;
                    }

                    return option;
                  },
                },
                {
                  title: 'Transaction Date',
                  field: 'createdAt',
                },
                {
                  title: 'Updated Date',
                  field: 'updatedAt',
                },

                {
                  title: 'Approval',
                  field: 'approve',
                  render: (rowData) => {
                    let option = null;
                    if (rowData.paymentStatus < 2) {
                      option = (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => fetchPartners(rowData._id, rowData.paymentTranch)}
                        >
                          Approve Transaction
                        </Button>
                      );
                    } else if (rowData.paymentStatus === 2 && rowData.completeStatus === 1) {
                      option = (
                        <Button variant="contained" color="success" onClick={()=>{
                          if(confirm("Are you sure you want to perform this operation. This action is irrevisible")){
                            fetch(BASE_URL+"/applications/billing/complete",{
                            method:"POST",
                            headers:{
                              "Content-Type":"application/json",
                              'x-access-token': token,
                            },
                            body: JSON.stringify({
                              billingId: rowData._id,
                              adminId: currentUser? currentUser._id: ''
                            })
                          }).then(res=>res.json())
                          .then(data=>{
                            if(data.error){
                              alert(data.error)
                            }else{
                              alert(data.success)
                              window.location.reload()
                            }
                          })
                          }
                        }}>
                          Mark as completed
                        </Button>
                      );
                    } else {
                      option = <Alert severity="success">Transaction completed</Alert>;
                    }
                    return option;
                  },
                },
                {
                  title: 'Partner',
                  field: 'checkPartner',
                  render: (rowData) =>
                    rowData && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            fetch(BASE_URL+'/setup/get-partner-by-billing-id', {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                'x-access-token': token,
                              },
                              body: JSON.stringify({
                                bill: id,
                              }),
                            })
                              .then((res) => res.json())
                              .then((data) => {
                                if (data.error) {
                                  setMessage(data.error);
                                  setOpen(true);
                                } else {
                                  alert(data.partner);
                                }
                              });
                          }}
                        >
                          Check Partner Attached
                        </Button>
                      </>
                    ),
                },

              ]}
              data={transaction}
              options={{
                pageSize: 1,
                exportButton: true,
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
