// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Account Management',
    path: '/dashboard/account',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'staff',
    path: '/dashboard/staff',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'proof-of-funds',
    path: '/dashboard/products/proof-of-funds',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'veritas visa',
    path: '/dashboard/products/veritas',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'General Setup',
    path: '/dashboard/set-up',
    icon: getIcon('eva:file-text-fill'),
  },
  // {
  //   title: 'Support',
  //   path: '/dashboard/support',
  //   icon: getIcon('eva:lock-fill'),
  // }
];

export default navConfig;
