// material
import { Container, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PendingTransactions from '../layouts/dashboard/components/pof/PendingTransactions';
import ProcessingTransaction from '../layouts/dashboard/components/pof/ProcessingTransaction';
// components
import Page from '../components/Page';

// Transactions
import AllPofTransaction from '../layouts/dashboard/components/pof/AllPofTransaction';
import CompleteTransactions from '../layouts/dashboard/components/pof/CompleteTransactions';
import AwaitingConfirmation from '../layouts/dashboard/components/pof/AwaitingConfirmation';

// ----------------------------------------------------------------------

export default function ProofOfFunds() {

  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('current_user'))

  useEffect(()=>{
    if(!currentUser){
      navigate("/",{replace: true})
    }
  }, [currentUser, navigate])
  return (
    <Page title="Dashboard: Products-Proof of Funds">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products- Proof of Funds
        </Typography>

        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <PendingTransactions />
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <AllPofTransaction />
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <CompleteTransactions />
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <ProcessingTransaction />
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <AwaitingConfirmation />
        
        
      </Container>
    </Page>
  );
}
