/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Button, Container, Stack, Typography, Skeleton } from '@mui/material';
import { BASE_URL } from '../utils/constants';
import { checkValidity } from '../layouts/dashboard/checkValidity';

// components
import Page from '../components/Page';


export default function ProductInformationOverview() {
//   const [isLoading, setIsLoading] = useState(true);
//   const [packages, setPackages] = useState([]);
 

//   const navigate = useNavigate();

  useEffect(() => {
    checkValidity()
      .then(() => {
        
        
      })
      .catch(() => {
        navigate('/');
      });
  });

  return (
    <>
      <Page title={`Veritas: ${service}`}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Product Information
            </Typography>
          </Stack>

          {/* {isLoading ? (
            <Stack spacing={1}>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" />
            </Stack>
          ) : (
            <EditVeritas packages={packages} />
          )} */}
        </Container>
      </Page>
    </>
  );
}
