/* eslint-disable no-alert */
import React, {useEffect} from 'react'
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// components
import { useNavigate } from 'react-router-dom';
import FetchStaff from '../layouts/dashboard/components/FetchStaff';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { RegisterForm } from '../sections/auth/register';


// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function Staff() {
  
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('current_user'))



  useEffect(()=>{
    if(!currentUser){
      navigate("/",{replace: true})
    }
  }, [currentUser, navigate])


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Page title="Staff: Staff Monitoring">
      <Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', marginBottom:'20px' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add New Staff
            </Typography>
            
          </Toolbar>
        </AppBar>
       <RegisterForm />
      </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Staff
          </Typography>
          <Button variant="contained" onClick={()=>{
            if(currentUser.privilege > 1){
              handleClickOpen()
            }else{
              alert("You are not authorized to access this resources")
            }
          }} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Staff
          </Button>
        </Stack>

        <FetchStaff />
      </Container>
    </Page>
  );
}
